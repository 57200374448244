import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Home from './pages/Home';
import Detail from './pages/Detail';
import ContactUs from './pages/ContactUs';
import NotFound from './pages/NotFound';
import reportWebVitals from './reportWebVitals';
import Nav from './pages/components/Nav'

import { Route, BrowserRouter as Router , Switch } from "react-router-dom";

const routing =(
  <Router>
    <div>
    
    <Switch>
      <Route exact path='/' component={Home} />
      <Route path='/detail/:id' component={Detail} />
      <Route path='/ContactUs' component={ContactUs} />
      <Route  component={NotFound} />
    </Switch>
    </div>
  </Router>
)


ReactDOM.render(routing,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
