import React, { useEffect, useState } from "react";
import style from "./components/css/detail.module.css";
import axios from "axios";

import loading from "./asset/loading.gif";


import Nav from './components/Nav'

import ReactHtmlParser from "react-html-parser";

const Detail = (props) => {
  const [detail, setdetail] = useState();

  const { params } = props.match;

  useEffect(() => {
    async function getdatas() {
      try {
        const res = await axios.get(
          "https://napoleon-wave.appspot.com/_ah/api/napoleon/v1/event_details/" +
            params.id
        );
        // console.log(params.id);
        setdetail(res.data);
      } catch (error) {
        console.log(error.response);
      }
    }

    getdatas();
  }, []);

  function createMarkup() {
    return { __html: "First &middot; Second" };
  }


  // this is to check if the data is done loading till then it shows a loading  gif 
  if (detail) {

    // this is to reformat the location data 
    const event_location = detail.event_location;
    const location = event_location.replace(
      /(([\w+]+\:\/\/)?([\w\d-]+\.)*[\w-]+[\.\:]\w+([\/\?\=\&\#]?[\w-]+)*\/?)/gm,
      '<a href="$1">$1</a>'
    );


    // this is to reformant the description data 
    const event_description = detail.event_description;
    const better = event_description.replace(
      /(([\w+]+\:\/\/)?([\w\d-]+\.)*[\w-]+[\.\:]\w+([\/\?\=\&\#]?[\w-]+)*\/?)/gm,
      '<a href="$1">$1</a>'
    );
    const br = better.replace(/[\n]/g, "</p> <p>");

    const hash = br.replace(
      /(^|\s)(#[A-Za-z\d]+)/gi,
      "$1<span class='hash'>$2</span>"
    );
    const username = hash.replace(
      /(^|\s)(@[A-Za-z\d]+)/gi,
      "$1<span class='username'>$2</span>"
    );
    let contact = ''
    let dates = ''


     if (detail.raw_date == undefined ) {
       if (detail.event_start_time == undefined ){
          dates = ''
       }else{
          dates = '<span class="material-icons"> date_range </span>' + detail.event_start_time
       }
    }else{
      dates = '<span class="material-icons"> date_range </span>' + detail.raw_date
      
     }


     if (detail.source == 'admin') {
       if (detail.event_organizers[0].email == undefined ){

         contact = '<br/>  <h4> Contact Us </h4>  <br/> <span> ' + detail.event_organizers[0].phone_number + '</span>'
      }
       if (detail.event_organizers[0].phone_number == undefined ){

         contact = '<br/>  <h4> Contact Us </h4>  <br/> <span> ' + detail.event_organizers[0].email + '</span>'
      }
      else{
       contact = '<br/>  <h4> Contact Us </h4>  <br/> <span> ' + detail.event_organizers[0].email + '</span>  <br/> <br/> <span> ' + detail.event_organizers[0].phone_number + '</span>'
      }
     }
     else if (detail.source == 'telegram') {
      if (detail.event_organizers[0].telegram_user_name != undefined ){
        contact = '<a href="https://t.me/' + detail.event_organizers[0].telegram_user_name + '" target="_blank" > <button> Contact Organizer </button></a>'
      }
     }
   else {
 

   }

    // reformating ends here 


    return (
      <div>
        <Nav/>
      <main>
        <div className={style.eventPics}>
          <img
            className={style.eventPic}
            src={detail.banner_image_url}
            alt=""
            srcset=""
            />
        </div>

        <div className={style.containt}>
          <h2>{detail.name}</h2>
          <p>
          {ReactHtmlParser(dates)}
          </p>
          <p>
            <span class="material-icons"> location_on </span>{" "}
            {ReactHtmlParser(location)}
          </p>
          <p>{ReactHtmlParser(username)}</p>
          
            <div className={style.contact} >

            {ReactHtmlParser(contact)}
            </div>
          
        </div>
      </main>
    </div>
    );
  } else {
    return (
      <main>
        <img
          src={loading}
          alt=""
          srcset=""
          />
      </main>
    );
  }
};
export default Detail;
