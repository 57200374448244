import React from 'react'
import {  Link } from "react-router-dom";

const NotFound = () => {
    return (
        <div>
            <h1>
                this page is deleted or not existed !!! 
            </h1>
            <Link to='/'> Back to Home</Link>
            <a href='https://t.me/eventethiopia'> Back to Telegram</a>

        </div>
    )
}

export default NotFound
