import React from "react";
import style from './css/home.module.css'
import logo from "../asset/Events Ethiopia.png";
import { Link } from "react-router-dom";

const Nav = () => {
  return (
    <nav>
      <div className={style.logo}>
        <img
          src={logo}
          alt=""
          srcset=""
        />
      </div>
      <div className={style.menu}>
          <Link to='/'>Home</Link>
      </div>
    </nav>
  );
};

export default Nav;
