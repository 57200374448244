import React from "react";
// import phones from "./asset/phones.png";
import style from "./components/css/home.module.css";

import twitter from "./asset/twittericon.png";
import fbicon from "./asset/fbicon.png";
import callicon from "./asset/callicon.png";
import background_image from "./asset/background image.jpg";

const Home = () => {
  return (
    <div>
      <div className={style.viewport1}>
        <div className={style.bgi}>
          <img
            src={background_image}
            alt=""
          />
        </div>
        <div className={style.center}>
          <h1 className={style.heading}>Events Ethiopia</h1>
          <p className={style.info}>
            Events in Addis Ababa and Ethiopia. Concert, Music, Art,
            Photography, Poetry, Technology. አዲስ አበባ
          </p>

          <div className={style.btn}>
            <a
              className={style.myButton}
              target="_blank" href="https://play.google.com/store/apps/details?id=com.master.deuce"
            >
              Download

            </a>


            <a target="_blank" href="https://t.me/EventsEthiopia" className={style.myButton}>
              Telegram{" "}
            </a>
          </div>
        </div>
      </div>

      <div className={style.viewport2}>
        <div className={style.center}>
          <h1 className={style.heading}>Promote Your Events</h1>
          <p className={style.info}>
            Reach over 100k+ users from a single Platform. Facebook, Twitter,
            Instagram, Tiktok, Google Groups
          </p>

          <div className={style.things3}>
            <div>
              <span class="material-icons md-48">groups</span>
              <h2 className={style.head}>+ 50,000</h2>
              <p className={style.subhead}>Members</p>
            </div>
            <div>
              <span class="material-icons">email</span>
              <h2 className={style.head}>+ 5000</h2>
              <p className={style.subhead}>Posts</p>
            </div>
            <div>
              <span class="material-icons">timelapse</span>
              <h2 className={style.head}>10 Years</h2>
              <p className={style.subhead}>Experiance</p>
            </div>
          </div>
        </div>
      </div>

      <div className={style.viewport3}>
        <div className={style.center}>
          <div>
            <h1 className={style.heading}>About Us</h1>
            <p className={style.info}>Events Ethiopia was established in July 2010 to help users find interesting events around their city. Want to catch a movie, attend music festival or spend quality time with family and friends. Install for your smartphone to see your city’s events.</p>
          </div>

          
        </div>
      </div>
      <div className={style.viewport3}>
        <div className={style.center}>
        <div>
            <h1 className={style.heading}>Contact Us </h1>
            <br/>
            <br/>
            <br/>
            <div className={style.links }>
                <a target="_blank" href="https://www.facebook.com/EventsEthiopia/"><img src={fbicon} alt="" srcset=""/></a>
                <a href="tel:+251 91 168 3121"><img src={callicon} alt="" srcset=""/></a>
                <a target="_blank" href="https://twitter.com/eventsethiopia"><img src={twitter} alt="" srcset=""/></a>
                

            </div>
          </div>

          
        </div>
      </div>
    </div>
  );
};

export default Home;
